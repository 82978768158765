<template>
  <div>
   <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="setConfirmLeaveForm = true">Болих</el-button>
          <el-button type="success" :disabled="loading" @click="addBlock('blockForm')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row v-loading="loading">
      <el-col :span="20" :offset="2">
        <custom-header :title="'Блок үүсгэх'"/>
        <el-form label-position="top" :model="blockForm" :rules="blockRules" ref="blockForm">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col>
                      <el-form-item label="Блок код" prop="super_block_code">
                        <el-input v-model="blockForm.super_block_code" placeholder="Админ дээр харагдах, fillter хийхэд ашиглах код, нэр ..."></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                   <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Байрлах хуудас" prop="page">
                        <el-select v-model="blockForm.page" placeholder="https://www.toktokmall.mn/ ..." class="block">
                          <el-option
                          v-for="page in blockPage"
                          :key="page.value"
                          :label="page.label"
                          :value="page.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" class="text-center">
                      <el-form-item label="Блокийн төлөв" prop="status">
                        <el-switch
                          style="display: block"
                          v-model="blockForm.status"
                          active-color="#13ce66"
                          inactive-color="#ff4949"
                          active-text="Идэвхтэй"
                          inactive-text="Идэвхгүй"
                          active-value="active"
                          inactive-value="expired">
                        </el-switch>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Гарчиг /Монгол/">
                        <el-input v-model="blockForm.name_mon" placeholder="Монгол гарчиг оруулах ..."></el-input>
                      </el-form-item>
                    </el-col>
                     <el-col :span="12">
                        <el-form-item label="Гарчиг /Англи/">
                          <el-input v-model="blockForm.name_eng" placeholder="Англи гарчиг оруулах ..."></el-input>
                        </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                    <el-form-item label="Гарчгийн өнгө">
                      <el-select
                            v-model="blockForm.title_color"
                            filterable
                            class="block">
                            <el-option
                              v-for="color in colorList"
                              :key="color.color_id"
                              :label="color.name_mon"
                              :value="color.hex_code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Жишээ">
                        <span :style="`color: ${ blockForm.title_color }; text-align: center; display: block; background-color: gray; border-color: gray; border-width: thin; border-style: solid; border-radius: 5px`"><strong>Гарчгийн сонгосон өнгө</strong></span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Гарчиг байрлал">
                        <el-select v-model="blockForm.title_location " placeholder="Дунд ..." class="block">
                          <el-option
                          v-for="position in titleLocation"
                          :key="position.value"
                          :label="position.label"
                          :value="position.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Гарчиг хэмжээ /web/">
                        <el-select v-model="blockForm.title_size" placeholder="Дунд ..." class="block">
                          <el-option
                          v-for="(sizeWeb, indexWeb) in titleSize"
                          :key="indexWeb"
                          :label="sizeWeb.label"
                          :value="sizeWeb.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-if="blockForm.title_size">
                    <el-col :span="12" :offset="12">
                      <el-form-item label="Гарчиг хэмжээ /утас/">
                        <el-select v-model="blockForm.title_size_mobile" placeholder="Утсан дээр харуулах хэмжээ ..." class="block">
                          <el-option
                          v-for="(sizeMobile, indexMobile) in titleSizeMobile"
                          :key="indexMobile"
                          :label="sizeMobile.label"
                          :value="sizeMobile.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Тайлбар /Монгол/">
                        <el-input
                        type="textarea" :rows="2" v-model="blockForm.description_mon" placeholder="Хар баасан гариг ...">
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Тайлбар /Англи/">
                        <el-input
                          type="textarea" :rows="2" v-model="blockForm.description_eng" placeholder="Black Friday ...">
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Дэвсгэр өнгийн төрөл" >
                        <el-checkbox-group v-model="set_type" @change="onChangeTypeBackground">
                        <el-checkbox label="Өнгө" prop="background"/>
                        <el-checkbox label="Зураг"/>
                      </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Эрэмбэ" prop="sort">
                        <el-input type="number" v-model="blockForm.sort" placeholder="1 ..."></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-if="set_type.includes('Өнгө')">
                    <el-col :span="12">
                       <el-select
                            v-model="color"
                            filterable
                            class="block">
                            <el-option
                              v-for="color in colorList"
                              :key="color.color_id"
                              :label="color.name_mon"
                              :value="color.hex_code">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="12">
                      <div :style="`background-color: ${ color }; height: 35px; border-color: gray; border-width: thin; border-style: solid; border-radius: 5px`"></div>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-if="set_type.includes('Зураг')">
                    <el-col>
                      <el-form-item>
                        <el-upload
                              list-type="picture-card"
                              accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                              action=""
                              :auto-upload="false"
                              :file-list="tempImagesBackground"
                              :on-remove="(file) => handleRemove(file, 'background')"
                              :on-change="(file) => getFile(file, 'background')">
                              <i class="el-icon-plus"></i>
                        </el-upload>
                        <loader :active="uploadingBackground"/>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" class="mt10">
                    <el-col :span="12">
                      <el-form-item label="Эхлэх огноо" prop="start_date">
                        <el-date-picker
                          value-format="yyyy-MM-dd HH:mm:ss"
                          type="datetime"
                          v-model="blockForm.start_date"
                          placeholder="Огноо сонгоно уу">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Дуусах огноо" v-if="set_end_date">
                        <el-date-picker
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-model="end_date"
                          type="datetime"
                          placeholder="Огноо сонгоно уу">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <div>
                      <el-checkbox v-model="set_end_date">Дуусах огноо оруулах</el-checkbox>
                    </div>
                  </el-row>
                </div>
              </div>
              <div class="panel">
                <div class='panel-item'>
                  <el-form :model="deviceForm" :rules="deviceFormRules" ref="deviceForm">
                    <el-row :gutter="20">
                      <el-col>
                        <header>Баганаар /Column/</header>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="Гар утас" prop="mobile">
                          <el-input type="number" placeholder="Тоо ширхэг ..." v-model="deviceForm.mobile"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="Таблет" prop="tablet">
                          <el-input type="number" placeholder="Тоо ширхэг ..." v-model="deviceForm.tablet"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="Зөөврийн комьпютер" prop="laptop">
                          <el-input type="number" placeholder="Тоо ширхэг ..." v-model="deviceForm.laptop"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Жижиг дэлгэцтэй комьпютер" prop="small_desktop">
                          <el-input type="number" placeholder="Тоо ширхэг ..." v-model="deviceForm.small_desktop"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Том дэлгэцтэй комьпютер" prop="big_desktop">
                          <el-input type="number" placeholder="Тоо ширхэг ..." v-model="deviceForm.big_desktop"></el-input>
                        </el-form-item>
                      </el-col>
                      <!-- <el-col :span="8">
                        <el-form-item label="Комьпютер" prop="desktop">
                          <el-input placeholder="Тоо ширхэг ..." v-model="desktop"></el-input>
                        </el-form-item>
                      </el-col> -->
                    </el-row>
                  </el-form>
                  <el-row :gutter="20">
                    <el-col>
                       <header>Ямар төхөөрөмж дээр харагдах эсэх?</header>
                    </el-col>
                    <el-col>
                      <el-checkbox v-model="checkAll" @change="checkDevices">Бүгд</el-checkbox>
                      <el-checkbox v-model="blockForm.mobile_show">Гар утас</el-checkbox>
                      <el-checkbox v-model="blockForm.tablet_show">Таблет</el-checkbox>
                      <el-checkbox v-model="blockForm.web_show">Веб</el-checkbox>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col class="mt20">
                      <header>Хөндлөнгөөр /Row/</header>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item>
                        <el-checkbox v-model="blockForm.is_campaign">Толгой зурагтай эсэх</el-checkbox>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        <el-checkbox @change="generateArrowShow" v-model="blockForm.arrow_show">Слайдтай эсэх</el-checkbox>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12" v-if="blockForm.is_campaign">
                      <el-form-item label="Толгой зураг">
                         <el-upload
                          list-type="picture-card"
                          accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                          action=""
                          :auto-upload="false"
                          :file-list="tempImagesHeadLogo"
                          :on-remove="(file) => handleRemove(file, 'headlogo')"
                          :on-change="(file) => getFileHeadLogo(file, 'headlogo')">
                          <i class="el-icon-plus"></i>
                        </el-upload>
                        <loader :active="uploadingHeadLogo"/>
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :span="12" v-if="blockForm.is_campaign">
                      <el-form-item label="Толгой зурагны жишээ">
                        <div style="border-style: dotted; border-width: 2px; height: 150px; width: 150px">
                          <el-image :src="src" style="width: 150px; height: 150px"></el-image>
                        </div>
                      </el-form-item>
                    </el-col> -->
                  </el-row>
                  <el-row v-if="blockForm.is_campaign">
                        <span style="color: red">Зөвхөн PNG өргөтгөлтэй зураг оруулна.</span>
                  </el-row>
                </div>
              </div>
            </el-col>
            <!-- Right-section -->
            <el-col :span="12">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20" class="mb15">
                    <el-col>
                      <el-button @click="confirmLeaveForm = true" type="primary">Блок нэмэх <i class="el-icon-plus"></i></el-button>
                      <el-checkbox v-if="blockForm.arrow_show" class="ml10" v-model="blockForm.first_img_lock">Слайд хөдөлдөггүй зурагтай эсэх</el-checkbox>
                    </el-col>
                  </el-row>
                  <el-row :gutter="10">
                    <draggable v-model="blockForm.images" group="people" @start="drag=true" @end="drag=false">
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" v-for="(image, index) in blockForm.images" :key="index" class="mt10">
                        <el-card :body-style="{ padding: '0px' }" shadow="hover">
                          <el-image :src="image.image" class="image" :fit="'contain'"></el-image>
                          <div style="padding: 14px;">
                            <div>
                              <a :href="image.url" target="_blank">
                                <span>{{image.url}}</span>
                              </a>
                            </div>
                            <div class="bottom clearfix text-right">
                              <el-button type="text" @click="deleteBlockItems(index)">Устгах</el-button>
                            </div>
                          </div>
                        </el-card>
                      </el-col>
                    </draggable>
                  </el-row>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col class="text-right">
                <el-button type="default" @click="setConfirmLeaveForm = true">Болих</el-button>
                <el-button type="success" :disabled="loading" @click="addBlock('blockForm')">Хадгалах</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <!-- CreateBlockDialog -->
    <el-dialog title="Блок үүсгэх" width="30%" :visible.sync="confirmLeaveForm">
      <div class="ml20 mr20">
        <el-row :gutter="20">
          <el-form label-position="top" :rules="blockItemRules" :model="blockItemForm" ref="blockItemForm">
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="Зураг">
                  <el-upload
                    list-type="picture-card"
                    accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                    action=""
                    :limit="1"
                    :file-list="tempImagesImage"
                    :auto-upload="false"
                    :on-remove="(file) => handleRemove(file, 'image')"
                    :on-change="(file) => getFileBlockImage(file, 'image')">
                    <span v-if="this.firstBlockImageSize !== null">{{this.firstBlockImageSize.width}}x{{this.firstBlockImageSize.height}}</span>
                    <i v-else class="el-icon-plus"></i>
                  </el-upload>
                  <loader :active="uploadingImage" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="Шилжих хуудас" prop="url">
                  <el-input v-model="blockItemForm.url" placeholder="https://toktokmall.mn"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false">Болих</el-button>
        <el-button type="success" @click="createBlockItems('blockItemForm')">
          Үүсгэх
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="setConfirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="setConfirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/block-list">
          <el-button type="danger" @click="setConfirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { handlers } from '../../../helpers/custom'
import Loader from '../../../components/common/loader'
import draggable from 'vuedraggable'
import services from '../../../helpers/services'
import CustomHeader from '../../../components/common/customHeader'
export default {
  name: 'BlockAddForm',
  components: {
    CustomHeader,
    draggable,
    Loader
  },

  data () {
    return {
      src: 'https://image.toktok.mn/jweAuIa0jf1627978534593.png',
      checkAll: false,
      colorList: [],
      show_rows_count: 1,
      head_logo: '',
      end_date: '',
      loading: false,
      uploadingBackground: false,
      uploadingHeadLogo: false,
      uploadingImage: false,
      tempImagesImage: [],
      tempImagesBackground: [],
      tempImagesHeadLogo: [],
      confirmLeaveForm: false,
      setConfirmLeaveForm: false,
      set_type: ['Өнгө'],
      set_end_date: false,
      color: '',
      deviceForm: {
        big_desktop: '',
        small_desktop: '',
        mobile: '',
        tablet: '',
        laptop: ''
      },

      blockItemForm: {
        url: '',
        // title_mon: '',
        // image_sort: '',
        image: ''
      },

      firstBlockImageSize: null,

      blockForm: {
        first_img_lock: false,
        super_block_code: '',
        title_size: '',
        title_size_mobile: '15px',
        title_color: '',
        status: 'active',
        title_location: '',
        is_campaign: false,
        arrow_show: false,
        mobile_show: false,
        tablet_show: false,
        web_show: false,
        background_type: '',
        background: '',
        sort: '',
        page: '',
        description_mon: '',
        description_eng: '',
        name_mon: '',
        name_eng: '',
        start_date: '',
        images: [],
        column: [],
        image_size: null
      },

      blockPage: [{
        value: 'https://www.toktokmall.mn/',
        label: 'Нүүр хуудас'
      }, {
        value: 'https://www.toktokmall.mn/brands',
        label: 'Брэнд хуудас'
      }, {
        value: 'https://www.toktokmall.mn/special',
        label: 'Онцлох'
      }, {
        value: 'https://www.toktokmall.mn/cooperation',
        label: 'Хамтран ажиллах'
      }, {
        value: 'https://www.toktokmall.mn/search',
        label: 'Mobile search'
      }, {
        value: 'https://www.toktokmall.mn/about-us',
        label: 'Бидний тухай'
      }, {
        value: 'https://www.toktokmall.mn/new',
        label: 'Шинэ'
      }, {
        value: 'https://www.toktokmall.mn/sale',
        label: 'Хямдрал'
      }, {
        value: 'https://www.toktokmall.mn/mongolian-brand',
        label: 'Монгол брэнд'
      }, {
        value: 'https://www.toktokmall.mn/group-order',
        label: 'Хамтдаа'
      }],

      blockStatus: [{
        value: 'active',
        label: 'Идэвхтэй'
      }, {
        value: 'expired',
        label: 'Идэвхгүй'
      }],

      titleLocation: [{
        value: 'left',
        label: 'Зүүн'
      }, {
        value: 'center',
        label: 'Дунд'
      }, {
        value: 'right',
        label: 'Баруун'
      }],

      titleSize: [{
        value: '30px',
        label: 'Жижиг'
      }, {
        value: '40px',
        label: 'Дунд'
      }, {
        value: '50px',
        label: 'Том'
      }, {
        value: '70px',
        label: 'Том /Large/'
      }],

      titleSizeMobile: [{
        value: '15px',
        label: 'Жижиг'
      }, {
        value: '20px',
        label: 'Дунд'
      }, {
        value: '25px',
        label: 'Том'
      }, {
        value: '40px',
        label: 'Том /Large/'
      }],

      blockItemRules: {
        url: [
          { required: true, message: 'Шилжих хуудсаа оруулна уу', trigger: 'blur' }
        ],
        title_mon: [
          { required: true, message: 'Блокны нэрээ оруулна уу', trigger: 'blur' }
        ],
        image_sort: [
          { required: true, message: 'Эрэмбэ оруулна уу', trigger: 'blur' }
        ]
      },

      deviceFormRules: {
        mobile: [
          { required: true, message: 'Тоогоо оруулна уу', trigger: 'blur' }
        ],
        tablet: [
          { required: true, message: 'Тоогоо оруулна уу', trigger: 'blur' }
        ],
        laptop: [
          { required: true, message: 'Тоогоо оруулна уу', trigger: 'blur' }
        ],
        big_desktop: [
          { required: true, message: 'Тоогоо оруулна уу', trigger: 'blur' }
        ],
        small_desktop: [
          { required: true, message: 'Тоогоо оруулна уу', trigger: 'blur' }
        ]
      },

      blockRules: {
        super_block_code: [
          { required: true, message: 'Блокны кодоо оруулна уу', trigger: 'blur' }
        ],
        name_mon: [
          { required: true, message: 'Блокны монгол нэрээ оруулна уу', trigger: 'blur' }
        ],
        page: [
          { required: true, message: 'Шилжих хуудсаа оруулна уу', trigger: 'blur' }
        ],
        description_mon: [
          { required: true, message: 'Тайлбараа оруулна уу', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: 'Эрэмбэ оруулна уу', trigger: 'blur' }
        ],
        status: [
          { required: true, message: 'Төлөв заавал сонгоно уу', trigger: 'blur' }
        ],
        start_date: [
          { required: true, message: 'Эхлэх хугацаа оруулна уу', trigger: 'blur' }
        ],
        background: [
          { required: true, message: 'Заавал сонгоно уу?', trigger: 'blur' }
        ]
      }
    }
  },

  created () {
    this.getColors()
  },

  methods: {
    generateArrowShow (data) {
      if (data === false) {
        this.blockForm.first_img_lock = false
      }
    },
    checkDevices () {
      if (this.checkAll === true) {
        this.blockForm.tablet_show = true
        this.blockForm.mobile_show = true
        this.blockForm.web_show = true
      } else {
        this.blockForm.tablet_show = false
        this.blockForm.mobile_show = false
        this.blockForm.web_show = false
      }
    },

    getColors () {
      services.getColors('').then(data => {
        this.colorList = data.datas
      })
    },

    addBlock (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.tempImagesHeadLogo.forEach(element => {
            this.head_logo = element.url
          })
          if (this.set_type.includes('Зураг')) {
            this.blockForm.background_type = 'image'
            this.tempImagesBackground.forEach(element => {
              this.blockForm.background = element.url
            })
          } else if (this.set_type.includes('Өнгө')) {
            this.blockForm.background_type = 'color'
            this.blockForm.background = this.color
          }
          var tempFormData = JSON.parse(JSON.stringify(this.blockForm))
          tempFormData.column.push(
            {
              big_desktop: parseInt(this.deviceForm.big_desktop),
              laptop: parseInt(this.deviceForm.laptop),
              mobile: parseInt(this.deviceForm.mobile),
              small_desktop: parseInt(this.deviceForm.small_desktop),
              tablet: parseInt(this.deviceForm.tablet)
            }
          )
          if (this.end_date !== '') {
            tempFormData.end_date = this.end_date
          }
          if (this.head_logo !== '') {
            tempFormData.head_logo = this.head_logo
          }
          if (this.show_rows_count !== '') {
            tempFormData.show_rows_count = parseInt(this.show_rows_count)
          }
          if (this.blockForm.sort !== '') {
            tempFormData.sort = parseInt(this.blockForm.sort)
          }
          if (this.blockForm.is_campaign === true) {
            tempFormData.is_campaign = true
          }
          this.loading = true
          services.addBlocks(tempFormData).then(response => {
            if (response.status === 'success') {
              this.alertReporter('Амжилттай', response.message, 'success')
              this.$router.push({ name: 'blockList' })
              this.loading = false
            } else if (response.status === 'error') {
              this.alertReporter('Алдаа гарлаа', response.message, 'error')
              this.loading = false
            } else {
              this.loading = false
            }
          })
        }
      })
    },

    createBlockItems (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.tempImagesImage.forEach(element => {
            this.blockItemForm.image = element.url
          })
          this.blockForm.images.push(
            {
              url: this.blockItemForm.url,
              image: this.blockItemForm.image
              // title_mon: this.blockItemForm.title_mon,
              // image_sort: this.blockItemForm.image_sort
            }
          )
          this.tempImagesImage = []
          this.blockItemForm.url = ''
          this.blockItemForm.title_mon = ''
          this.blockItemForm.image_sort = ''
          this.confirmLeaveForm = false
        }
      })
    },

    deleteBlockItems (comingIndex) {
      const blockItems = JSON.parse(JSON.stringify(this.blockForm.images))
      const tempItems = []
      blockItems.forEach((element, index) => {
        if (index !== comingIndex) {
          tempItems.push(element)
        }
      })
      if (tempItems.length === 0) {
        this.firstBlockImageSize = null
        this.blockForm.image_size = null
      }
      this.blockForm.images = tempItems
    },

    onChangeTypeBackground (type) {
      if (type.length === 2) {
        const tempType = JSON.parse(JSON.stringify(this.set_type))
        tempType.splice(0, 1)
        this.set_type = tempType
      }
    },

    async setFirstImageSize (file) {
      const self = this
      const promise = new Promise((resolve) => {
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.src = _URL.createObjectURL(file.raw)
        img.onload = function () {
          var tempSize = { width: 0, height: 0 }
          tempSize.width = img.width
          tempSize.height = img.height
          self.firstBlockImageSize = tempSize
          self.blockForm.image_size = tempSize
          resolve()
        }
      })
      await promise
    },

    async getFileBlockImage (file, type) {
      let isReady = true
      if (!this.firstBlockImageSize) {
        await this.setFirstImageSize(file)
        isReady = false
      }
      handlers.checkImageSize(file, isReady, this.firstBlockImageSize ? this.firstBlockImageSize.width : 0, this.firstBlockImageSize ? this.firstBlockImageSize.height : 0).then(() => {
        this.uploadingImage = true
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            this.uploadingImage = false
            if (response.status === 'success') {
              this.tempImagesImage.push({
                url: response.url
              })
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file, type)
        this.$message.error(error.message)
      })
    },

    getFile (file, type) {
      handlers.checkImageSize(file, false).then(() => {
        if (type === 'background') {
          this.uploadingBackground = true
        }
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            if (type === 'background') {
              this.uploadingBackground = false
            }
            if (response.status === 'success') {
              if (type === 'background') {
                this.tempImagesBackground.push({
                  url: response.url
                })
              }
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file, type)
        this.$message.error(error.message)
      })
    },

    getFileHeadLogo (file, type) {
      handlers.checkImageSize(file).then(() => {
        if (type === 'headlogo') {
          this.uploadingHeadLogo = true
        }
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            if (type === 'headlogo') {
              this.uploadingHeadLogo = false
            }
            if (response.status === 'success') {
              if (type === 'headlogo') {
                this.tempImagesHeadLogo.push({
                  url: response.url
                })
              }
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file, type)
        this.$message.error(error.message)
      })
    },

    handleRemove (file, type) {
      var tempId = 0
      if (type === 'background') {
        this.tempImagesBackground.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImagesBackground.splice(tempId, 1)
      } else if (type === 'headlogo') {
        this.tempImagesHeadLogo.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImagesHeadLogo.splice(tempId, 1)
      } else if (type === 'image') {
        this.tempImagesImage.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImagesImage.splice(tempId, 1)
      } else {
        this.$message.error('Уучлаарай алдаа гарлаа!')
      }
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    }
  }
}
</script>
